export default {
    SET_AFTERCARE_DETAILS: (state, payload) => {
        state.aftercareDocumentDetails = payload;
    },
    SET_AFTERCARE_FORM_SUBMITTING: (state, payload) => {
        state.isSubmitting = payload;
    },
    SET_AFTERCARE_FORM_SUBMISSION_COMPLETED: (state, payload) => {
        state.isSubmissionCompleted = payload;
    },
    SET_PREVIEW_LOADING: (state, payload) => {
        state.isPreviewing = payload;
    }
}