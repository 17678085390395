import axios from "@/axios";

export default {
  fetchTemplates({ commit }, data) {
    return new Promise((resolve, reject) => {
      const fetchTemplatesUrl =
        "/api/v2/aftercare/templates/list?organizationId=" +
        data.organizationId;
      axios
        .get(fetchTemplatesUrl)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchAftercareProducts({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v2/aftercare/products/list", {
          params: {
            search: data.search,
            organizationId: data.organizationId,
            id: data.templateId,
          }
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
  fetchTemplateDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v2/aftercare/templates/${data.id}`)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
  createAftercareTemplate({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v2/aftercare/templates", data)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
  updateAftercareTemplate({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/v2/aftercare/templates/${data.id}`, data.body)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
  previewAftercareTemplate({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v2/aftercare/preview", data)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
  fetchAftercareGlobalSettings({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v2/aftercare/global-settings?organizationId=${data.organizationId}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateAftercareDetails({ commit }, payload) {
    commit('SET_AFTERCARE_DETAILS', payload);
  },
  updateFormSubmission({ commit }, payload) {
    commit('SET_AFTERCARE_FORM_SUBMITTING', payload);
  },
  updateAftercareFormCompletion({ commit }, payload) {
    commit('SET_AFTERCARE_FORM_SUBMISSION_COMPLETED', payload)
  },
  setPreviewLoading({ commit }, payload) {
    commit('SET_PREVIEW_LOADING', payload);
  }
};
