import axios from "@/axios";

export default {
  async fetchConsentForms({ commit }, data) {
    const request = new Promise((resolve, reject) => {
      axios
        .get("/api/v1/consent-forms", { params: data })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    const result = await request;

    commit('MUTATE_CONSENT_FORMS', result.data.data)
  },
  createDefaultConsentForms({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/consent-forms/create-default", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createConsentForm({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/consent-forms", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateConsentForm({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/v1/consent-forms/${data.id}`, data.body)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchConsentFormInfo({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/consent-forms/${data.id}`, { params: data.params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createConsentFormQuestion({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/consent-forms/${data.id}/question`, data.body)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateConsentFormQuestion({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/v1/consent-forms/${data.consentId}/question/${data.id}`, data.body)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchConsentFormQuestion({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/consent-forms/${data.consentId}/question/${data.id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  reOrderConsentFormQuestion({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/consent-forms/question/re-order`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteConsentForm({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/v1/consent-forms/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createCustomForm({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/consent-forms/create", data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
    })
  },
  updateCustomForm({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/v1/consent-forms/update/${data.consentId}`, data.body)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
    })
  },
  updateConsentFormDetails({ commit }, consentFormInfo) {
    commit('SET_CONSENT_FORM', consentFormInfo);
  },
  updatedFormSubmission({ commit }, isSubmitting) {
    commit('SET_IS_SUBMITTING', isSubmitting)
  },
  updateConsentFormCompletion({ commit }, submissionCompleted) {
    commit('SET_SUBMISSION_COMPLETE', submissionCompleted)
  }
};
