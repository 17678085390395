/*=========================================================================================
  File Name: moduleAuthGetters.js
  Description: Auth Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  getConsentForm: (state) => state.consentFormInfo,
  isFormSubmitting: (state) => state.isSubmitting,
  isSubmissionCompleted: (state) => state.submissionCompleted,
};
