import state from './moduleAftercareState.js'
import mutations from './moduleAftercareMutation.js'
import actions from './moduleAftercareActions.js'
import getters from './moduleAftercareGetters.js'

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
